import Turbolinks from 'turbolinks'
import Rails from '@rails/ujs'
import $ from 'jquery'

// Prevent stale CSRF tokens
document.addEventListener('turbolinks:load', Rails.refreshCSRFTokens)
document.addEventListener('turbolinks:before-cache', function () {
  $('.modal, .modal-backdrop, .alert').remove()
})

Turbolinks.start()
