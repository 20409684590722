import { confirm } from 'bootbox'

function railsConfirmationHandler (message, element) {
  if (element.dataset.confirmed) return true

  confirm({
    message,
    centerVertical: true,
    buttons: {
      confirm: {
        label: element.dataset.confirmOk,
        className: 'btn-danger'
      },
      cancel: {
        label: element.dataset.confirmCancel
      }
    },
    callback (confirmed) {
      if (confirmed) {
        element.dataset.confirmed = true
        element.click()
      }
    }
  })

  return false
}

export { railsConfirmationHandler as confirm }
