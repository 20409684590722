import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  connect () {
    $(this.element).on('keyup', function (e) {
      const pwd = $(this).val()
      if (pwd.length >= 8) {
        $('#pws_length i').removeClass('fa-times-circle text-primary').addClass('fa-check-circle text-secondary')
      } else {
        $('#pws_length i').removeClass('fa-check-circle text-secondary').addClass('fa-times-circle text-primary')
      }
      if (pwd.match(/[0-9]/g)) {
        $('#pws_number i').removeClass('fa-times-circle text-primary').addClass('fa-check-circle text-secondary')
      } else {
        $('#pws_number i').removeClass('fa-check-circle text-secondary').addClass('fa-times-circle text-primary')
      }
      if (pwd.match(/[a-z]/g) && pwd.match(/[A-Z]/g)) {
        $('#pws_letter i').removeClass('fa-times-circle text-primary').addClass('fa-check-circle text-secondary')
      } else {
        $('#pws_letter i').removeClass('fa-check-circle text-secondary').addClass('fa-times-circle text-primary')
      }
      if (pwd.match(/[@#$%^&+=.:\-!?]/g)) {
        $('#pws_special_sign i').removeClass('fa-times-circle text-primary').addClass('fa-check-circle text-secondary')
      } else {
        $('#pws_special_sign i').removeClass('fa-check-circle text-secondary').addClass('fa-times-circle text-primary')
      }
    })
  }

  disconnect () {
    $(this.element).off('keyup')
  }
}
